import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import FileUploads from "./components/FileUploads";
import Header from "./components/Header";
import Login from "./components/Login";
import Protected from "./components/Protected";
import React, { useEffect, useState } from "react";
import ProductionSheetUpload from "./components/ProductionSheetUpload";
import PostProductionSheetUpload from "./components/PostProductionSheetUpload";
import Table from "./components/Table";
import BulkUpload from "./components/BulkUpload";
import Dashboard from "./components/Dashboard";
import FileUploadHistory from "./components/FileUploadHistory";
import UserLogin from "./components/UserLogin";
import { getEnvvariable } from "./util/env";
import { toast } from 'react-toastify';
import { sentinelAuthValidation } from "./util/defaultvalues";
import axios from "axios";

let intervalId;// Set the desired locale
const locale = "en", messages = {
  greeting: "Hello, World!",
  "user.login-title": "Login",
  "user.email": "Username",
  "user.login-button": "LOGIN",
};
function App() {
  const [isLoggedIn, setisLoggedIn] = useState(localStorage.getItem("styli_fileUpload") !== null ? true : false);
  const [apiData, setApiData] = useState([]);

  const refreshFirebaseToken = async () => {// refresh token main functionality
    console.log('Refreshing Firebase Token...');
    try {
      const refreshToken = localStorage.getItem('s_rtoken');
      console.log('sentinel refreshToken', refreshToken)
      const result = await axios.post(
        `${process.env.SENTINEL_URL}/v1/auth/regenerate-token`,
        { refreshToken },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('sentinel refresh token result', result?.data)
      if (result?.data?.status) {
        if (result?.data?.data?.token) {
          localStorage.setItem('s_token', result?.data?.data?.token);
        }
        if (result?.data?.data?.refreshToken) {
          localStorage.setItem('s_rtoken', result?.data?.data?.refreshToken);
        }
      } else {
        console.log('refresh token server error', result)
        toast.error(`Internal Server Error`, { position: 'top-right' });
      }
    } catch (error) {
      console.log('refresh token server error', error)
      toast.error(`Internal Server Error`, { position: 'top-right' });
    }
  };

  useEffect(() => {
    const sentinel_refresh_token = localStorage.getItem('s_rtoken')
    if (isLoggedIn) {
      if (sentinel_refresh_token && !intervalId && sentinelAuthValidation) {
        refreshFirebaseToken();
        intervalId = setInterval(() => {
          refreshFirebaseToken();
        }, 10 * 60 * 1000);
        console.log('intervalId', intervalId)
      }
    }
    else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoggedIn])

  return (
    <>
      <IntlProvider locale={locale} messages={messages}>
        <Router>
          <div>
            <Switch>
              <Route path="/fileUploads">
                <Protected>
                  <FileUploads />
                </Protected>
              </Route>
              <Route path="/productionsheetupload">
                <Protected>
                  <ProductionSheetUpload />
                </Protected>
              </Route>
              <Route path="/postproductionsheetupload">
                <Protected>
                  <PostProductionSheetUpload />
                </Protected>
              </Route>
              <Route path="/bulkupload">
                <Protected>
                  <BulkUpload />
                </Protected>
              </Route>
              <Route path="/fileuploadhistory">
                {/* <Protected> */}
                <FileUploadHistory tableData={apiData} />
                {/* </Protected> */}
              </Route>
              <Route path="/">
                <UserLogin setisLoggedIn={setisLoggedIn} />
              </Route>
              {/* <Route path="/header">
              <Protected>
                <Header />
              </Protected>
              </Route> */}
              {/* <Route path="/history">
                <Table tableData={apiData} />
              </Route> */}
              {/* <Route path="/dashboard">
              <Protected>
                <Dashboard />
              </Protected>
              </Route> */}
              {/* <Route path="/login">
                <Login setisLoggedIn={setisLoggedIn} />
              </Route> */}
            </Switch>
          </div>
        </Router>
      </IntlProvider>
    </>
  );
}
export default App;
