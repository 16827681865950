import { getEnvvariable } from "./env";
let getEnv = getEnvvariable();
export const sentinelAuthValidation = getEnv?.sentinelAuthValidation
export const sentinel_url = getEnv?.SENTINEL_URL

console.log('sentinelAuthValidation',sentinelAuthValidation)
export const set_auth_token = async (additionalData) => {
    const sentinel_auth_token = localStorage.getItem('s_token')
    let styli_fileUpload = localStorage.getItem('styli_fileUpload') || "{}";
    let userId = JSON.parse(styli_fileUpload)?.user?.userId;
    if (additionalData?.headers && userId) {
        additionalData['headers']['user-id'] = userId;
    }
    if (sentinelAuthValidation && sentinel_auth_token && additionalData?.headers) {
        additionalData.headers['authorization-token'] = sentinel_auth_token
        delete additionalData.headers['Authorization']
    }
    return additionalData
}