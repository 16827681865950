import React, { useState } from "react";
import { ReactSpreadsheetImport } from "subhasmitalmg-react-spreadsheet-import";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getEnvvariable } from "../util/env";
import { axiosPost, getToken } from "../util/helper";
const envConfig = getEnvvariable();

const apiUrl = envConfig.REACT_APP_API_URL;

export default function ProductionSheetUpload() {
  const history = useHistory();
  const OPTIONMASS = [
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONCSS = [
    { value: "STARTED", label: "STARTED" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONPSS = [
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONSVSAI = [
    { value: "PASS", label: "PASS" },
    { value: "CONDITIONAL PASS", label: "CONDITIONAL PASS" },
    { value: "FAIL", label: "FAIL" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONPLI = [
    { value: "PASS", label: "PASS" },
    { value: "CONDITIONAL PASS", label: "CONDITIONAL PASS" },
    { value: "FAIL", label: "FAIL" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONSC = [
    { value: "IN", label: "IN" },
    { value: "CN", label: "CN" },
  ];
  // const isOpen = true ;
  const [isOpen, setIsOpen] = useState(true);
  const autoMapSelectValues = true;
  const DownloadDbData = async () => {
    try {

      let token = getToken();
      const response = await axiosPost(
        // "http://localhost:8080/fileuploader/productionlist",
        apiUrl + "/fileuploader/productionlist",
        {},
        {headers: {Authorization: `${token}`}}
      );
      const filelink = response.data.filelink;

      const link = document.createElement("a");
      link.href = filelink;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("styli_fileUpload");
        history.push("/");
        window.location.reload(true);
      }else if (error.response && error.response.status === 500) {
        console.error("Internal Server Error:", error.response.data);
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  };
  const onClose = () => {
    setIsOpen(false);
    // history.push("/header");
    history.push("/bulkupload");
  };
  const onDownload = (data, file) => {
    const allData = data.all;

    const invalidRows = [];
    allData.filter((item) => {
      if (item?.__errors) {
        invalidRows.push(item);
      }
    });
    const output = [];
    const headerRow = [
      "FPO date",
      "Sourcing Country",
      "PO Number",
      "Option Id",
      "Material Arrival QC Status",
      "Cutting Stage Status",
      "Packing Stage Status",
      "Vendor AQL Inspection Status",
      //"Packing List Inspection Status",
      "Error Message",
    ];
    output.push(headerRow);

    invalidRows.forEach((item) => {
      const rowData = [
        item["fpo_date"],
        item["sourcing_country"],
        item["po_number"],
        item["option_id"],
        item["material_arrival_qc_status"],
        item["cutting_stage_status"],
        item["packing_stage_status"],
        item["vendor_aql_inspection_status"],
        //item["packing_list_inspection_status"],
        item.__errors
          ? Object.values(item.__errors)
              .map((error) => error.message)
              .join(",")
          : "",
      ];
      output.push(rowData);
    });

    const XLSX = require("xlsx");
    const print = {
      invalidData: output,
    };
    const ws = XLSX.utils.aoa_to_sheet(print.invalidData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Invalid Data");

    XLSX.writeFile(wb, "download_errors_prod.xlsx");
  };
  const onSubmit = (data, file) => {
    try {
      console.log("data", data);
      const validdata = data.validData;
      const storedItem = localStorage.getItem('styli_fileUpload');
      const parsedItem = JSON.parse(storedItem);
      const firstname = parsedItem.user.firstname;
      // console.log('Firstname:', firstname);
      const username = firstname;

      let token = getToken();
     axiosPost(apiUrl + "/fileuploader/uploadproductdata", {
      // axios
      //   .post("http://localhost:8080/fileuploader/uploadproductdata", {
          validdata,
          username
        }, {headers: {Authorization: `${token}`}})
        .then((response) => {
          console.log("API Response:", response.data);
          console.log("Data Updated Successfully");
        })
        .catch((error) => {
          console.error("An error occurred during API request:", error);
        });
      console.log("Data Updated Successfully");
    } catch (error) {
      console.error("An error occurred:", error);
    }

    setIsOpen(true);
  };
  var fields = [
    {
      label: "FPO date",
      key: "fpo_date",
      alternateMatches: ["FPO date"],
      fieldType: {
        type: "input",
      },
      example: "03-05-23",
      validations: [
        {
          rule: "required",
          errorMessage: "FPO date is required",
          level: "error",
        },
      ],
    },
    {
      label: "Sourcing Country",
      key: "sourcing_country",
      alternateMatches: ["Sourcing Country"],
      fieldType: {
        type: "select",
        options: OPTIONSC,
      },
      example: "IN",
      validations: [
        {
          rule: "required",
          errorMessage: "Sourcing country is required",
          level: "error",
        },
      ],
    },
    {
      label: "PO Number",
      key: "po_number",
      alternateMatches: ["PO Number"],
      fieldType: {
        type: "input",
      },
      example: "70010067",
      validations: [
        {
          rule: "required",
          errorMessage: "PO Number is required",
          level: "error",
        },
        {
          rule: "regex",
          errorMessage: "PO Number should be numeric only",
          value: "^[0-9]+$",
        },
      ],
    },
    {
      label: "Option Id",
      key: "option_id",
      alternateMatches: ["Option Id"],
      fieldType: {
        type: "input",
      },
      example: "7005238902",
      validations: [
        {
          rule: "required",
          errorMessage: "Option Id is required",
          level: "error",
        },
        {
          rule: "regex",
          errorMessage: "Option Id should be numeric only",
          value: "^[0-9]+$",
        },
      ],
    },
    {
      label: "Vendor Manager",
      key: "vendor_manager",
      alternateMatches: ["Vendor Manager"],
      fieldType: {
        type: "input",
      },
      example: "XYZ",
      validations: [
        {
          rule: "required",
          errorMessage: "Vendor Manager is required",
          level: "error",
        },
      ],
    },
    {
      label: "Material Arrival QC Status",
      key: "material_arrival_qc_status",
      alternateMatches: ["Material Arrival QC Status"],
      fieldType: {
        type: "select",
        options: OPTIONMASS,
      },
      example: "STARTED",
      validations: [
        {
          rule: "required",
          errorMessage: "Material Arrival QC Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Cutting Stage Status",
      key: "cutting_stage_status",
      alternateMatches: ["Cutting Stage Status"],
      fieldType: {
        type: "select",
        options: OPTIONCSS,
      },
      example: "STARTED",
      validations: [
        {
          rule: "required",
          errorMessage: "Cutting Stage Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Packing Stage Status",
      key: "packing_stage_status",
      alternateMatches: ["Packing Stage Status"],
      fieldType: {
        type: "select",
        options: OPTIONPSS,
      },
      example: "STARTED",
      validations: [
        {
          rule: "required",
          errorMessage: "Packing Stage Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Vendor AQL Inspection Status",
      key: "vendor_aql_inspection_status",
      alternateMatches: ["Vendor AQL Inspection Status"],
      fieldType: {
        type: "select",
        options: OPTIONSVSAI,
      },
      example: "STARTED",
      validations: [
        {
          rule: "required",
          errorMessage: "Vendor AQL Inspection Status is required",
          level: "error",
        },
      ],
    },
    // {
    //   label: "Packing List Inspection Status",
    //   key: "packing_list_inspection_status",
    //   alternateMatches: ["Packing List Inspection Status"],
    //   fieldType: {
    //     type: "select",
    //     options: OPTIONPLI,
    //   },
    //   example: "STARTED",
    //   validations: [
    //     {
    //       rule: "required",
    //       errorMessage: "Packing List Inspection Status is required",
    //       level: "error",
    //     },
    //   ],
    // },
  ];
  return (
    <div>
      <ReactSpreadsheetImport
        translations={{
          uploadStep: {
            title: "Production file upload",
          },
          alerts:{
            submitSuccess: {
              successMessage: "File submission is done, check the result in History page.It will appear in some minutes.",
            },
          }
        }}
        customTheme={{
          components: {
            UploadStep: {
              baseStyle: {
                dropzoneButton: {
                  bg: "green",
                },
              },
            },
          },
        }}
        rowHook={(data, addError) => {
          const material_arrival_qc_status = data.material_arrival_qc_status;
          const cutting_stage_status = data.cutting_stage_status;
          const packing_stage_status = data.packing_stage_status;
          const vendor_aql_inspection_status =
            data.vendor_aql_inspection_status;
        //  const packing_list_inspection_status = data.packing_list_inspection_status;
          const sourcing_country = data.sourcing_country;
          // console.log(typeof material_qc);

          if (
            material_arrival_qc_status === "PENDING" &&
            cutting_stage_status != "PENDING"
          ) {
            addError("cutting_stage_status", {
              message:
                "Cutting Stage Status cannot be STARTED if Material Arrival QC Status is PENDING",
              level: "error",
            });
          }
          if (
            material_arrival_qc_status === "PENDING" &&
            packing_stage_status != "PENDING"
          ) {
            addError("packing_stage_status", {
              message:
                "Packing Stage Status cannot be COMPLETED if Material Arrival QC Status is PENDING",
              level: "error",
            });
          }
          if (
            packing_stage_status === "PENDING" &&
            vendor_aql_inspection_status != "PENDING"
          ) {
            addError("vendor_aql_inspection_status", {
              message:
                "Vendor AQL Inspection Status cannot be PASS/CONDITIONAL PASS/FAIL if Packing Stage Status is PENDING",
              level: "error",
            });
          }
          // if (
          //   packing_stage_status === "PENDING" &&
          //   packing_list_inspection_status != "PENDING" && sourcing_country === "IN"
          // ) {
          //   addError("packing_list_inspection_status", {
          //     message:
          //       "Packing List Inspection Status cannot be PASS/CONDITIONAL PASS/FAIL if Packing Stage Status is PENDING",
          //     level: "error",
          //   });
          // }
          if (
            packing_stage_status === "COMPLETED" &&
            cutting_stage_status != "STARTED"
          ) {
            addError("packing_stage_status", {
              message:
                "Packing Stage Status can only be COMPLETED if Cutting Stage Status is STARTED",
              level: "error",
            });
          }
          return data;
        }}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        onDownload={onDownload}
        DownloadDbData={DownloadDbData}
        autoMapSelectValues={autoMapSelectValues}
        fields={fields}
      />
    </div>
  );
}
