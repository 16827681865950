import "../assets/css/UserLogin.css";
import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getEnvvariable } from "../util/env";
import loginBkground from "../assets/images/loginBkground.png";
import eyeIcon from "../assets/images/Group.svg"; // Add the path to your eye icon image
import { axiosPost } from "../util/helper";
import { sentinel_url, sentinelAuthValidation } from "../util/defaultvalues";

const envConfig = getEnvvariable();
const apiUrl = envConfig.REACT_APP_API_URL_SP;

const UserLogin = ({ setisLoggedIn = () => { } }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);



  async function sentinelLoginFunctionality(email, password) {//login process via sentinal
    const apiUrl = `${sentinel_url}/v1/auth/login`;
    const url = new URL(envConfig.REACT_APP_API_URL);
    const hostname = url.hostname;
    try {
      let response = await axios.post(apiUrl, {
        email,
        password,
        domain: hostname,
      })
      console.log('sentinelLoginfunctionality res', response.data)
      return response.data;
    }
    catch (e) {
      console.error('sentinelLoginfunctionality error here', e);
      return e;
    }
  }
  const onButtonClick = async (e) => {
    setEmailError("");
    setPasswordError("");

    if ("" === email) {
      setEmailError("Please enter your username");
      return;
    }
    if ("" === password) {
      setPasswordError("Please enter a password");
      return;
    }
    e.preventDefault();

    try {
      const resp = await axiosPost(apiUrl + "/v1/login/user", {
        username: email,
        password: password,
        sentinelAuthValidation: sentinelAuthValidation,
        isTerra: true
      });

      if (resp?.data?.statusCode === '200') {
        let final_response = resp?.data?.response;
        let sentinelEmail = final_response?.user?.email;

        if (sentinelAuthValidation) {
          let sentinelLoginResponse = await sentinelLoginFunctionality(sentinelEmail, password)
          console.log('sentinelLoginResponse', sentinelLoginResponse)
          if (sentinelLoginResponse?.status === true) {
            final_response.luna_info = sentinelLoginResponse?.data
            localStorage.setItem('s_token', sentinelLoginResponse?.data?.token);
            localStorage.setItem('s_rtoken', sentinelLoginResponse?.data?.refreshToken);
            onLoginSuccess(resp);
          }
          else if (sentinelLoginResponse?.status === false && sentinelLoginResponse?.data?.message) {
            alert(sentinelLoginResponse?.data?.message)
          }
          else {
            alert("internal server error for login")
          }
        }
        else {
          onLoginSuccess(resp);
        }

      }
      else if (resp?.data?.statusCode === "500") {
        alert("internal server error for login")
      }
      else {
        alert(resp?.response?.data?.message)
      }


    } catch (error) {
      console.error("An error occurred:", error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("styli_fileUpload");
        history.push("/");
        window.location.reload(true);
      }
    }
  };

  function onLoginSuccess(resp) {
    console.log("login success", resp);
    localStorage.setItem(
      "styli_fileUpload",
      JSON.stringify(resp.data.response)
    );
    setisLoggedIn(true);
    history.push("/bulkupload");
  }

  return (
    <div className="eta-bulk-upload">
      <img className="eta-bulk-upload-child" alt="" src={loginBkground} />
      <div className="eta-bulk-upload-item" />
      <img className="group-icon" alt="" src="/group@2x.png" />
      <div className="welcome-to-sourcing">
        Welcome to Sourcing Progress Upload
      </div>
      <div className="please-use-your">
        <label className="errorLabel">{passwordError}</label>
        <label className="errorLabel">{emailError}</label>
        {/* Please use your Supplier Portal user name and password */}
      </div>
      <div className="user-name-parent">
        <div className="user-name">User name</div>
        <div className="rectangle-parent">
          <input
            value={email}
            // placeholder=""
            onChange={(ev) => setEmail(ev.target.value)}
            className="manjunath"
          />
        </div>
      </div>
      <div className="password-parent">
        <div className="user-name">Password</div>
        <div className="group-parent">
          <input
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={(ev) => setPassword(ev.target.value)}
            className="manjunath"
          />
        </div>
        <img
          src={eyeIcon}
          alt="eye"
          className="eye-icon"
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      <div className="login-wrapper">
        <input
          className="login"
          type="button"
          onClick={onButtonClick}
          value={"Login"}
        />
      </div>
    </div>
  );
};

export default UserLogin;
