import axios from "axios"
import { set_auth_token } from "./defaultvalues";
let flag;
export function getToken() {
  try {
    let fileUploadObject = localStorage.getItem('styli_fileUpload');
    let parsedObject = fileUploadObject ? JSON.parse(fileUploadObject) : null;
    let token = parsedObject && parsedObject.token ? parsedObject.token : null;
    return token
  } catch (error) {
    console.error('Error fetching token from localStorage:', error.message);
    return null
  }
}

export async function axiosGet(url = "", additionalData = {}) {//common function for sending get request
  await set_auth_token(additionalData)
  try {
    let response = await axios.get(url, additionalData);
    return response;

  } catch (error) {
    if (error.status === 401) {
      // forceLogout(error?.response?.data?.message);
    }
    if (error.status === 403) {
      // forceLogout(authErrorMsg);
    }
    return error

  }
}

export async function axiosPost(url = "", body = {}, additionalData = {}) {//common function for sending post request 
  await set_auth_token(additionalData)
  try {
    let response = await axios.post(url, body, additionalData)
    return response;
  } catch (error) {
    console.log("error", error)
    let authErrorMsg = 'User Not Authorized to access this service';
    if (error.status === 401) {
      // forceLogout(error?.response?.data?.message);
    }
    if (error.status === 403) {
      // forceLogout(authErrorMsg);
    }
    return error
  }
}

function forceLogout(errorMessage) {
  if (!flag) {
    localStorage.removeItem("styli_fileUpload");
    // setisLoggedIn(false)
    localStorage.removeItem('s_token')
    localStorage.removeItem('s_rtoken')
    alert(errorMessage)
    window.location.reload(true);
    flag = true;
  }
}